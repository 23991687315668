import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";

import { Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  createInvoicesMieter,
  createInvoicesObjects,
} from "helpers/backend_helper";

const Verrechnung = props => {
  const [kmObjekte, setKMObjekte] = useState(20);
  const [loading, setLoading] = useState(false);

  const handleVerrechnungObjekte = async () => {
    try {
      const txtObjnr = document.getElementById("txtObjnr").value;
      if (!txtObjnr) return alert("Keine Objektnummern angegeben!");
      const objNrs = txtObjnr?.split(",");

      if (objNrs.length > 10)
        return alert(
          "Es dürfen nur maximal 10 Objekte gleichzeitig verrechnet werden"
        );
      setLoading(true);
      const result = await createInvoicesObjects(objNrs, "" + kmObjekte);
      setLoading(false);
      alert(`Es wurden ${result?.invoicesCount} Rechnungen erstellt!`);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Es ist ein unerwarteter Fehler aufgetreten!");
    }
  };

  const handleVerrechnungMieter = async () => {
    try {
      const txtLfdNrs = document.getElementById("txtLfdNrs").value;
      if (!txtLfdNrs) return alert("Keine Laufnummern angegeben!");
      const lfdNrs = txtLfdNrs?.split(",");

      if (lfdNrs.length > 10)
        return alert(
          "Es dürfen nur maximal 10 Mieter gleichzeitig verrechnet werden"
        );

      const txtStrafe = document.getElementById("txtStrafbetrag").value;

      if (!txtStrafe) return alert("Kein Strafbetrag angegeben!");

      const strafbetrag = parseFloat(txtStrafe);

      if (isNaN(strafbetrag)) return alert("Strafbetrag ist keine Zahl!");

      setLoading(true);
      const result = await createInvoicesMieter(lfdNrs, strafbetrag);
      setLoading(false);
      alert(`Es wurden ${result?.invoicesCount} Rechnungen erstellt!`);
    } catch (error) {
      setLoading(false);

      if (error?.response?.status === 400) alert(error.response.data);
      else alert("Es ist ein unerwarteter Fehler aufgetreten!");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Übersicht" breadcrumbItem="Berichte" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <h4 className="mb-4">Sammelfaktura</h4>
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Objektnummern (mit &quot;,&quot; getrennt)
                        </Label>
                        <textarea
                          id="txtObjnr"
                          className="form-control"
                          rows="2"
                          placeholder="z.B.: 1001,1002,1003,1004"
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">Kilometer:</Label>
                        <div className="">
                          <select
                            className="form-control"
                            onChange={e => setKMObjekte(e.target.value)}
                          >
                            <option value="20">bis 20km</option>
                            <option value="70">bis 70km</option>
                            <option value="120">bis 120km</option>
                            <option value="> 120">ab 120km</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col lg="2">
                      <button
                        className="btn btn-primary mt-auto mb-auto"
                        onClick={handleVerrechnungObjekte}
                      >
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Jetzt verrechnen
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <h4 className="mb-4">Einzelfaktura</h4>
                  <Row>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Laufnummern (mit &quot;,&quot; getrennt)
                        </Label>
                        <textarea
                          id="txtLfdNrs"
                          className="form-control"
                          rows="2"
                          placeholder="z.B.: 10001,10002,10003,10004"
                        />
                      </div>
                    </Col>
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="basicpill-address-input1">
                          Strafbetrag in €:
                        </Label>
                        <Input
                          id="txtStrafbetrag"
                          className="form-control"
                          rows="2"
                          placeholder="z.B.: 100"
                        />
                      </div>
                    </Col>
                    <Col lg="2">
                      <button
                        className="btn btn-primary mt-auto mb-auto"
                        onClick={handleVerrechnungMieter}
                      >
                        {loading && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        )}
                        Jetzt verrechnen
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Verrechnung);
