import { getFirebaseBackend } from "helpers/firebase_helper";
import { isAdmin } from "helpers/misc_helper";
import moment from "moment";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const Overview = ({ objekt, setLoading, reload }) => {
  const [modal, setModal] = useState(false);
  const [bezeichnung, setBezeichnung] = useState(objekt.bezeichnung);
  const [anzWE, setAnzWE] = useState(objekt.anzWE);
  const firebase = getFirebaseBackend();

  const handleSave = async () => {
    try {
      setLoading(true);
      const value = parseInt(anzWE);

      await firebase.updateObjekt({ ...objekt, anzWE: value, bezeichnung });
      reload();
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("Es ist ein unerwarteter Fehler aufgetreten!");
    }
  };

  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Überblick</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Objektnr.:</th>
                    <td scope="col">{objekt.objnr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Strasse:</th>
                    <td scope="col">{objekt.bezeichnung}</td>
                  </tr>
                  <tr>
                    <th scope="col">Ort:</th>
                    <td scope="col">{objekt.plz + " " + objekt.ort}</td>
                  </tr>
                  <tr>
                    <th scope="col">Anz. WE:</th>
                    <td scope="col">{objekt.anzWE}</td>
                  </tr>
                  <tr>
                    <th scope="col">Anz. RWM.:</th>
                    <td scope="col">{objekt.anzRWM}</td>
                  </tr>
                  <tr>
                    <th scope="col">GBV:</th>
                    <td scope="col">{objekt.gbv}</td>
                  </tr>
                  <tr>
                    <th scope="col">Datum Tausch:</th>
                    <td scope="col">
                      {moment(objekt.datumTausch.toDate()).format(
                        "DD.MM.YYYY"
                      ) +
                        " " +
                        objekt.uhrzeitTausch}
                    </td>
                  </tr>

                  <tr>
                    <th scope="col">Monteur:</th>
                    <td scope="col">{objekt.monteur}</td>
                  </tr>
                  <tr>
                    <th scope="col">Gbv:</th>
                    <td scope="col">{objekt.gbv}</td>
                  </tr>
                  <tr>
                    <th scope="col">Verrechnet am:</th>
                    <td scope="col">
                      {objekt.verrechnet != 0 ? objekt.verrechnet : "-"}
                    </td>
                  </tr>
                  {isAdmin() && (
                    <tr>
                      <td colSpan={2}>
                        <button
                          className="btn btn-soft-danger mx-auto d-block"
                          onClick={_ => setModal(true)}
                        >
                          Stammdaten bearbeiten
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={modal}
        centered
        toggle={() => {
          setModal();
        }}
        id="applyJobs"
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => setModal()}
            id="applyJobsLabel"
            className="modal-header"
          >
            Stammdaten
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={10} className="mb-3">
                <Label>Adresse:</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={bezeichnung}
                  onChange={e => setBezeichnung(e.target.value)}
                />
              </Col>

              <Col lg={10} className="mb-3">
                <Label>Anzahl WE:</Label>
                <Input
                  type="text"
                  className="form-control"
                  value={anzWE}
                  onChange={e => setAnzWE(e.target.value)}
                />
              </Col>

              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button className="btn btn-success ms-1" onClick={handleSave}>
                    Speichern
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Overview;
