import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Select from "react-select";
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import { mieterColumns } from "./mieterColumns";
import { handleMieterExcelExport } from "helpers/csv_helper";
const Mieter = props => {
  const node = React.createRef();
  const firebase = getFirebaseBackend();
  const [allMieter, setAllMieter] = useState([]);
  const [filteredMieter, setFilteredMieter] = useState([]);
  const [loading, setLoading] = useState(true);
  const storageDateStr = localStorage.getItem("filterDate");
  const monteurFilter = localStorage.getItem("filterMonteur");
  const storageDate = storageDateStr ? new Date(storageDateStr) : new Date();
  const [filterDate, setFilterDate] = useState(storageDate);

  const monteurOptions = [
    {
      options: [
        {
          label: "Monteur 1",
          value: "Monteur 1",
        },
        {
          label: "Monteur 2",
          value: "Monteur 2",
        },
        {
          label: "Monteur 3",
          value: "Monteur 3",
        },
        {
          label: "Monteur 4",
          value: "Monteur 4",
        },
        {
          label: "Alle",
          value: "Alle",
        },
      ],
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: filteredMieter.length,
    custom: true,
  };

  useEffect(() => {
    getMieter();
  }, []);

  async function getMieter(date) {
    const result = await firebase.getMieter(date ?? filterDate);
    result.forEach(r => {
      r.erledigt = "";
      r.notiz = r.notiz ?? "";
    });
    sortMieter(result);
    setAllMieter(result);
    setFilteredMieter(filterMieter(result));
    setLoading(false);
  }

  const filterMieter = results => {
    const monteurFilter = localStorage.getItem("filterMonteur");
    if (!monteurFilter || monteurFilter === "Alle") return results;
    return results.filter(r => r.monteur === monteurFilter);
  };

  const sortMieter = mieter => {
    mieter.sort((a, b) => {
      const datumA = a.datumTausch3 ?? a.datumTausch2 ?? a.datumTausch;
      const datumB = b.datumTausch3 ?? b.datumTausch2 ?? b.datumTausch;
      const uhrzeitA = a.uhrzeitTausch3 ?? a.uhrzeitTausch2 ?? a.uhrzeitTausch;
      const uhrzeitB = b.uhrzeitTausch3 ?? b.uhrzeitTausch2 ?? b.uhrzeitTausch;

      const strA = datumA + uhrzeitA;
      const strB = datumB + uhrzeitB;
      let result = strA.localeCompare(strB);

      if (result === 0) result = a.lfdNr < b.lfdNr ? -1 : 1;

      return result;
    });
  };

  const handleDatumFilter = async e => {
    localStorage.setItem("filterDate", e[0]);
    setFilterDate(e[0]);
    getMieter(e[0]);
  };

  const handleMonteurFilter = async e => {
    localStorage.setItem("filterMonteur", e.value);
    const result = filterMieter(allMieter);
    setFilteredMieter(result);
  };

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="lfdNr"
                      columns={mieterColumns || []}
                      data={filteredMieter || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="lfdNr"
                          data={filteredMieter}
                          columns={mieterColumns || []}
                          remote={{ filter: true }}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="3" className="my-2">
                                  <Flatpickr
                                    className="form-control d-block"
                                    lang="de"
                                    value={filterDate}
                                    multiple={false}
                                    onChange={handleDatumFilter}
                                    options={{
                                      dateFormat: "d.m.Y",
                                      locale: {
                                        ...Austria,
                                        firstDayOfWeek: 1,
                                      },
                                    }}
                                  />
                                </Col>
                                <Col sm="3" className="my-2">
                                  <Select
                                    options={monteurOptions}
                                    className="select2-selection"
                                    placeholder="auswählen"
                                    defaultValue={
                                      monteurFilter
                                        ? {
                                            label: monteurFilter,
                                            value: monteurFilter,
                                          }
                                        : undefined
                                    }
                                    onChange={handleMonteurFilter}
                                  />
                                </Col>
                                <Col sm="3" className="my-2">
                                  <button
                                    className="btn btn-primary"
                                    onClick={_ =>
                                      handleMieterExcelExport(filteredMieter)
                                    }
                                  >
                                    Export Excel
                                  </button>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  bordered={false}
                                  hover={true}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Mieter);
