import React from "react";
import { Col, Input, Label, Row, TabPane } from "reactstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { executionOptions } from "./setupOptions";

const MeldungenPane = ({ report, setReport }) => {
  return (
    <TabPane tabId={3} style={{ maxWidth: "800px" }} className="mx-auto">
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Label>Bemerkung des Monteurs:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.monteurBemerkung ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  monteurBemerkung: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Label>Bemerkung zur Wartung von Bewohner: :</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.bewohnerBemerkung ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  bewohnerBemerkung: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="mb-3">
            <Label>Falls Reklamation:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.reklamation ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  reklamation: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default MeldungenPane;
