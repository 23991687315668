import React from "react";
import { Link } from "react-router-dom";

export const mieterColumns = [
  {
    dataField: "lfdNr",
    text: "LfdNr",
    sort: true,
    formatter: (cellContent, row) => (
      <Link to="#" className="text-body fw-bold">
        {row.lfdNr}
      </Link>
    ),
  },
  {
    dataField: "objNr",
    text: "ObjNr.",
    sort: true,
  },
  {
    dataField: "lfd",
    text: "WLfdNr",
    sort: true,
  },
  {
    dataField: "vorName",
    text: "Vorname",
    sort: true,
  },
  {
    dataField: "famName",
    text: "Nachname",
    sort: true,
  },
  {
    dataField: "adresse",
    text: "Adresse",
    sort: true,
  },
  {
    dataField: "ort",
    text: "Ort",
    sort: true,
  },
  {
    dataField: "datumTausch",
    text: "Datum",
    sort: true,
    formatter: (_, row) => (
      <>{row.datumTausch3 ?? row.datumTausch2 ?? row.datumTausch} </>
    ),
  },
  {
    dataField: "uhrzeitTausch",
    text: "Uhrzeit",
    sort: true,
    formatter: (_, row) => (
      <>{row.uhrzeitTausch3 ?? row.uhrzeitTausch2 ?? row.uhrzeitTausch}</>
    ),
  },
  {
    dataField: "notiz",
    text: "Notiz",
    sort: true,
    formatter: (_, row) => <>{row.notiz ?? ""}</>,
  },
  {
    dataField: "monteur",
    text: "Monteur",
    sort: true,
  },
];
