import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axios.defaults.headers["apiKey"] = "c2f0e351-a70e-4f8e-a645-3160b95257e8";

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(
      url,
      { ...data },
      { ...config, headers: { apiKey: "c2f0e351-a70e-4f8e-a645-3160b95257e8" } }
    )
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
