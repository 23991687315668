import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ReactSignatureCanvas from "react-signature-canvas";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { isAdmin } from "helpers/misc_helper";

const DetailsSection = ({ report, setLoading }) => {
  const firebase = getFirebaseBackend();
  const mieterSignatureRef = useRef();
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async () => {
    if (mieterSignatureRef.current.isEmpty()) return setError(true);
    setLoading(true);
    report.genehmigungSignatur = mieterSignatureRef.current.toDataURL();
    await firebase.updateBericht(report);

    setLoading(false);
  };

  useEffect(() => {
    if (report.genehmigungSignatur) setDone(true);
  }, [report]);

  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <Row className="mb-4">
              <div>
                <h4 className="mb-3">Abstellgenehmigung</h4>
                <p style={{ maxWidth: "800px" }}>
                  Ich, {(report.vorName ?? "") + " " + (report.famName ?? "")},
                  bestätige hiermit, dass ich die von der Manilumi GmbH
                  bereitgestellten Rauchmelder erhalten habe, um diese selbst zu
                  montieren. <br />
                  <br />
                  Ich übernehme die Verantwortung für die Abnahme der alten
                  Rauchmelder von der Magnetplatte und positioniere den neuen
                  Rauchmelder auf der bestehenden Magnetplatte.
                  <br />
                  <br /> Etwaige Schäden oder Veränderungen an den Rauchmeldern,
                  die während der Montage oder danach auftreten, werde ich
                  unverzüglich melden und gegebenenfalls für die Reparatur oder
                  den Ersatz verantwortlich sein.
                </p>
              </div>
            </Row>

            <h4>Unterschrift</h4>
            <div className="w-100 h-50">
              {report.genehmigungSignatur ? (
                <img src={report.genehmigungSignatur} />
              ) : (
                <ReactSignatureCanvas
                  penColor="blue"
                  backgroundColor="lightgrey"
                  clearButton="true"
                  redrawOnResize
                  ref={mieterSignatureRef}
                  canvasProps={{
                    width: 300,
                    height: 200,
                    className: "sigCanvas",
                  }}
                />
              )}
            </div>

            {!isAdmin() && (
              <button className="btn btn-primary mt-4" onClick={handleSubmit}>
                Absenden
              </button>
            )}
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={done && !isAdmin()} centered>
        <div className="modal-content">
          <ModalHeader className="modal-header">Bestätigung</ModalHeader>
          <ModalBody>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-success text-success bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-check"></i>
              </div>
            </div>
            <Row className="text-center">
              <h4>Bestätigung erfolgreich!</h4>
              <p>
                Vielen Dank für Ihre Zustimmung. Bei Fragen sind wir gerne für
                Sie erreichbar.
              </p>
            </Row>
          </ModalBody>
        </div>
      </Modal>

      <Modal isOpen={error} centered>
        <div className="modal-content">
          <ModalHeader className="modal-header">Fehler</ModalHeader>
          <ModalBody>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-alert-circle-outline"></i>
              </div>
            </div>
            <Row className="text-center">
              <h4>Unterschrift erfolgreich!</h4>
              <p>
                Bitte unterschreiben Sie im grauen Unterschriftsfeld. Vielen
                Dank!
              </p>

              <button
                className="btn btn-primary w-auto mx-auto"
                onClick={_ => setError(false)}
              >
                Okay
              </button>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DetailsSection;
