import React from "react";
import { Col, Input, Label, Row, TabPane } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import moment from "moment";
import { isAdmin } from "helpers/misc_helper";

const ObjectDetailsPane = ({
  report,
  setReport,
  setModal,
  setModalLastTermin,
  setModalLastTerminEnfernen,
  setError,
}) => {
  return (
    <TabPane tabId={1} style={{ maxWidth: "1200px" }} className="mx-auto">
      <Row className="text-end">
        <Col>
          {isAdmin() && (
            <>
              {report?.listeLetzerTermin ? (
                <>
                  {report.letzterTerminZeit && (
                    <>(Monteur war dort am: {report.letzterTerminZeit})</>
                  )}
                  <input
                    type="button"
                    className="btn btn-primary m-2"
                    defaultValue="aus 3. Terminliste entfernen"
                    onClick={() => {
                      setModalLastTerminEnfernen(true);
                    }}
                  />
                </>
              ) : (
                <>
                  {report.gbv !== 5 && (
                    <input
                      type="button"
                      className="btn btn-primary me-4"
                      defaultValue="2. Termin festlegen"
                      onClick={() => {
                        if (report.datumTausch2)
                          setError("Es gibt bereits einen 2. Austauschtermin!");
                        else setModal(true);
                      }}
                    />
                  )}
                  <input
                    type="button"
                    className="btn btn-primary"
                    defaultValue={
                      report.gbv !== 5
                        ? "3. Termin festlegen"
                        : "Mieter nicht anwesend"
                    }
                    onClick={() => {
                      if (!report.datumTausch2 && !report.gbv === 5)
                        setError("Es gibt noch keinen 2. Austauschtermin!");
                      else setModalLastTermin(true);
                    }}
                  />
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Objekt Nr.:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.objNr ?? ""}
              readOnly
            />
          </div>
        </Col>

        <Col lg={4}>
          <div className="mb-3">
            <Label>VE:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.verrNr ?? ""}
              readOnly
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>LfNr:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.lfdNr ?? ""}
              readOnly
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Datum:</Label>
            <Flatpickr
              className="form-control d-block"
              lang="de"
              value={report?.datumTausch ?? ""}
              onChange={e => {
                if (e.length === 0)
                  setReport({
                    ...report,
                    datumTausch: undefined,
                  });
                else
                  setReport({
                    ...report,
                    datumTausch: moment(e[0]).format("DD.MM.YYYY"),
                  });
              }}
              options={{
                dateFormat: "d.m.Y",
                locale: {
                  ...Austria,
                  firstDayOfWeek: 1,
                },
              }}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Uhrzeit:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.uhrzeitTausch ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  uhrzeitTausch: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Kunde:</Label>
            <Input
              type="text"
              className="form-control"
              value={(() => {
                if (report?.gbv == 1) return "KÄRNTNER HEIMSTÄTTE";
                if (report?.gbv == 2) return "NEUE HEIMAT";
                if (report?.gbv == 5) return "KÄRNTNERLAND";
              })()}
              readOnly
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Instandhaltungstermin 2:</Label>
            <Flatpickr
              className="form-control d-block"
              lang="de"
              value={report?.datumTausch2 ?? ""}
              onChange={e => {
                if (e.length === 0)
                  setReport({
                    ...report,
                    datumTausch2: undefined,
                  });
                else
                  setReport({
                    ...report,
                    datumTausch2: moment(e[0]).format("DD.MM.YYYY"),
                  });
              }}
              options={{
                dateFormat: "d.m.Y",
                locale: {
                  ...Austria,
                  firstDayOfWeek: 1,
                },
              }}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Uhrzeit:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.uhrzeitTausch2 ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  uhrzeitTausch2: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Instandhaltungstermin 3:</Label>
            <Flatpickr
              className="form-control d-block"
              lang="de"
              value={report?.datumTausch3 ?? ""}
              onChange={e => {
                if (e.length === 0)
                  setReport({
                    ...report,
                    datumTausch3: undefined,
                  });
                else
                  setReport({
                    ...report,
                    datumTausch3: moment(e[0]).format("DD.MM.YYYY"),
                  });
              }}
              options={{
                dateFormat: "d.m.Y",
                locale: {
                  ...Austria,
                  firstDayOfWeek: 1,
                },
              }}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>Uhrzeit:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.uhrzeitTausch3 ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  uhrzeitTausch3: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Wohnungslfnr:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.lfd ?? ""}
              readOnly
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Straße:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.adresse ?? ""}
              readOnly
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Postleitzahl:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.plz ?? ""}
              readOnly
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Ort:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.ort ?? ""}
              readOnly
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Anrede Bewohner/in:</Label>
            <Input
              type="mail"
              className="form-control"
              value={report?.anrede ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  anrede: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Nachname Bewohner/in:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.famName ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  famName: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Vorname Bewohner/in:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.vorName ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  vorName: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label>E-Mail Bewohner/in:</Label>
            <Input
              type="mail"
              className="form-control"
              value={report?.email ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  email: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Telefon Bewohner/in:</Label>
            <Input
              type="tel"
              className="form-control"
              value={report?.telnr ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  telnr: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label>Notiz:</Label>
            <Input
              type="text"
              className="form-control"
              value={report?.notiz ?? ""}
              onChange={e =>
                setReport({
                  ...report,
                  notiz: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
    </TabPane>
  );
};

export default ObjectDetailsPane;
