import React from "react";
import { Col, Row, TabPane } from "reactstrap";

import ReactSignatureCanvas from "react-signature-canvas";

const SignutarePane = ({ monteurSignatureRef, mieterSignatureRef }) => {
  return (
    <TabPane tabId={4} style={{ maxWidth: "1000px" }} className="mx-auto">
      <Row>
        <Col className="mt-4" align="center">
          <h3>Unterschrift Monteur:</h3>
          <div className="w-100 h-50">
            <ReactSignatureCanvas
              penColor="blue"
              backgroundColor="lightgrey"
              clearButton="true"
              redrawOnResize
              ref={monteurSignatureRef}
              canvasProps={{
                width: 350,
                height: 200,
                className: "sigCanvas",
              }}
            />
          </div>
        </Col>

        <Col className="mt-4" align="center">
          <h3>Unterschrift Mieter:</h3>
          <ReactSignatureCanvas
            penColor="blue"
            backgroundColor="lightgrey"
            ref={mieterSignatureRef}
            canvasProps={{
              width: 350,
              height: 200,
              className: "sigCanvas",
            }}
          />
        </Col>
      </Row>
    </TabPane>
  );
};

export default SignutarePane;
