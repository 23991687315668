import { printBericht, sendSMS } from "helpers/backend_helper";
import { getFirebaseBackend } from "helpers/firebase_helper";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const DetailsSection = ({ report, setLoading }) => {
  const [smsModal, setSMSModal] = useState(false);
  const firebase = getFirebaseBackend();

  async function printPDF() {
    setLoading(true);
    const result = await printBericht(report);

    const file = new Blob([result], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;

    link.setAttribute("download", `Bericht-${report.objNr}.pdf`);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(fileURL);
    link.remove();
    setLoading(false);
  }

  async function sendGenehmigungSMS() {
    setLoading(true);
    const telNr = document.getElementById("telNr").value;
    await sendSMS(report.lfdNr, telNr);
    await firebase.updateBericht({ ...report, smsSent: new Date() });
    setLoading(false);
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <Row>
              <div>
                <Row>
                  <Col lg="6">
                    <h4 className="mb-5">Brandmelder:</h4>
                  </Col>
                  <Col lg="6 text-end d-flex justify-content-end align-items-center">
                    {report?.genehmigungSignatur ? (
                      <Link
                        to={"/abstellgenehmigung/" + report.lfdNr}
                        className="btn btn-primary me-3"
                      >
                        Abstellgenehmigung
                      </Link>
                    ) : (
                      <button
                        className="btn btn-primary me-3"
                        id="print-button"
                        onClick={_ => setSMSModal(true)}
                      >
                        SMS senden
                      </button>
                    )}
                    <button
                      className="btn btn-primary"
                      id="print-button"
                      onClick={printPDF}
                    >
                      Bericht drucken
                    </button>
                  </Col>
                </Row>
                <ul className="verti-timeline list-unstyled">
                  {report?.brandmelder?.map((experience, i) => (
                    <li className="event-list" key={"_exp_" + i}>
                      <div className="event-timeline-dot">
                        <i className="bx bx-right-arrow-circle" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-15">{experience.raum}</h5>
                            <span className="text-primary">
                              {experience.status}
                            </span>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Row>
            <Row className="mt-5">
              <Col>
                <h5 className="fw-semibold mb-3">Unterschrift Monteur</h5>
                <img src={report.monteurSignature} />
              </Col>
              <Col>
                <h5 className="fw-semibold mb-3">Unterschrift Mieter/in</h5>
                <img src={report.mieterSignature} />
              </Col>
              {report.genehmigungSignatur && (
                <Col>
                  <h5 className="fw-semibold mb-3">Unterschrift Genehmigung</h5>
                  <img src={report.genehmigungSignatur} />
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={smsModal} toggle={_ => setSMSModal(false)} centered>
        <div className="modal-content">
          <ModalBody>
            <button
              type="button"
              onClick={_ => setSMSModal(false)}
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <div className="avatar-sm mb-4 mx-auto">
              <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
                <i className="mdi mdi-cellphone"></i>
              </div>
            </div>
            <Row>
              <h4 className="text-center mb-4">
                Abstellerlaubnis per SMS senden?
              </h4>

              <div className="mb-3">
                <Label className="form-label">Telefonnumer:</Label>
                <input
                  className="form-control"
                  id="telNr"
                  type="tel"
                  defaultValue={report.telnr ?? ""}
                />
              </div>

              <button
                className="btn btn-primary w-auto mx-auto"
                onClick={sendGenehmigungSMS}
              >
                SMS senden
              </button>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default DetailsSection;
