export const statusOption = [
  {
    options: [
      { label: "ausgetauscht", value: "ausgetauscht" },
      { label: "ergänzt", value: "ergänzt" },
    ],
  },
];

export const roomOptions = [
  {
    options: [
      { label: "Vorraum", value: "Vorraum" },
      { label: "Wohnzimmer", value: "Wohnzimmer" },
      { label: "Schlafzimmer", value: "Schlafzimmer" },
      { label: "Kinderzimmer", value: "Kinderzimmer" },
      { label: "Esszimmer", value: "Esszimmer" },
      { label: "Loggia", value: "Loggia" },
    ],
  },
];

export const executionOptions = [
  {
    options: [
      {
        label: "Austausch ohne Mängel & Schäden abgeschlossen",
        value: "Austausch ohne Mängel & Schäden abgeschlossen",
      },
      {
        label: "Austausch nicht durchgeführt, Mieter/in nicht Zuhause",
        value: "Austausch nicht durchgeführt, Mieter/in nicht Zuhause",
      },
    ],
  },
];
