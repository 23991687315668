export const objektListe = [
  {
    objnr: "3031",
    bezeichnung: "Seegasse 37",
    plz: "9020",
    ort: "Klagenfurt",
    gbv: "5",
    gbvName: "Kärntnerland",
    fvw: "0",
    hvAbteilung: "",
    anzWE: "1",
    anzRWM: "4",
    datumTausch: "12/29/23",
    uhrzeitTausch: "08:00-09:00",
    monteur: "M1",
  },
  {
    objnr: "20541",
    bezeichnung: "Prof. Deutsch-Gasse 9",
    plz: "9020",
    ort: "Klagenfurt",
    gbv: "5",
    gbvName: "Kärntnerland",
    fvw: "0",
    hvAbteilung: "",
    anzWE: "1",
    anzRWM: "4",
    datumTausch: "12/29/23",
    uhrzeitTausch: "08:00-09:00",
    monteur: "M1",
  },
  {
    objnr: "21041",
    bezeichnung: "Schächtestraße 34/E/3",
    plz: "9500",
    ort: "Villach",
    gbv: "5",
    gbvName: "Kärntnerland",
    fvw: "0",
    hvAbteilung: "",
    anzWE: "1",
    anzRWM: "4",
    datumTausch: "12/29/23",
    uhrzeitTausch: "08:00-09:00",
    monteur: "M1",
  },
];
