import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import { mieterColumns } from "./mieterColumns";
import { handleMieterExcelExport } from "helpers/csv_helper";
import Select from "react-select";
import { isAdmin } from "helpers/misc_helper";

const Mieter = props => {
  const node = React.createRef();
  const firebase = getFirebaseBackend();
  const [mieter, setMieter] = useState([]);
  const [filteredMieter, setFilteredMieter] = useState([]);
  const [loading, setLoading] = useState(true);
  const filterGBV = localStorage.getItem("filterGBV");
  const { SearchBar } = Search;
  const gbvOptions = [
    {
      options: [
        {
          label: "Kärntnerland",
          value: "Kärntnerland",
        },
        {
          label: "Alle",
          value: "Alle",
        },
      ],
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: mieter.length,
    custom: true,
  };

  const handleSelectRow = row => {
    props.history.push("/mieter/" + row.lfdNr);
  };

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: handleSelectRow,
  };

  useEffect(() => {
    getLetzteTermine();
  }, []);

  async function getLetzteTermine() {
    let result = await firebase.getLetzteTermine();
    result.forEach(r => {
      r.notiz = r.notiz ?? "";
    });
    sortReports(result);
    setMieter(result);
    result = filterMieter(result);
    setFilteredMieter(result);
    setLoading(false);
  }

  const sortReports = reports => {
    reports.sort((a, b) => {
      const datumA = a.datumTausch3 ?? a.datumTausch2 ?? a.datumTausch;
      const datumB = b.datumTausch3 ?? b.datumTausch2 ?? b.datumTausch;
      const uhrzeitA = a.uhrzeitTausch3 ?? a.uhrzeitTausch2 ?? a.uhrzeitTausch;
      const uhrzeitB = b.uhrzeitTausch3 ?? b.uhrzeitTausch2 ?? b.uhrzeitTausch;

      const strA = datumA + uhrzeitA;
      const strB = datumB + uhrzeitB;
      let result = strA.localeCompare(strB);

      if (result === 0) result = a.lfdNr < b.lfdNr ? -1 : 1;

      return result;
    });
  };

  const handleGBVFilter = async e => {
    localStorage.setItem("filterGBV", e.value);
    const result = filterMieter(mieter);
    setFilteredMieter(result);
  };

  const filterMieter = results => {
    const gbvFilter = isAdmin()
      ? localStorage.getItem("filterGBV")
      : "Kärntnerland";
    if (!gbvFilter || gbvFilter === "Alle") return results;
    return results.filter(r => r.gbv === 5);
  };

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="lfdNr"
                      columns={mieterColumns || []}
                      data={mieter || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="lfdNr"
                          data={filteredMieter}
                          columns={mieterColumns || []}
                          remote={{ filter: true }}
                          bootstrap4
                          exportCSV={{ separator: ";" }}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="d-flex justify-content-end">
                                    {isAdmin() && (
                                      <Select
                                        options={gbvOptions}
                                        className="select2-selection me-4 w-25"
                                        placeholder="auswählen"
                                        defaultValue={
                                          filterGBV
                                            ? {
                                                label: filterGBV,
                                                value: filterGBV,
                                              }
                                            : undefined
                                        }
                                        onChange={handleGBVFilter}
                                      />
                                    )}
                                    <button
                                      className="btn btn-primary"
                                      onClick={_ =>
                                        handleMieterExcelExport(mieter)
                                      }
                                    >
                                      Export Excel
                                    </button>
                                  </div>
                                </Col>
                              </Row>

                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  bordered={false}
                                  hover={true}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Mieter);
