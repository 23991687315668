export const brandmelderInitialValues = [
  { id: 1, raum: "Vorraum" },
  { id: 2, raum: "Wohnzimmer" },
  {
    id: 3,
    raum: "Schlafzimmer",
  },
  {
    id: 4,
    raum: "Kinderzimmer",
  },
];
