import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import Select from "react-select";
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import { mieterColumns } from "./mieterColumns";
import { isAdmin } from "helpers/misc_helper";

const Reports = props => {
  const node = React.createRef();
  const firebase = getFirebaseBackend();
  const [allReports, setAllReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const storageDateStr = localStorage.getItem("filterDate");
  const monteurFilter = localStorage.getItem("filterMonteur");
  const storageDate = storageDateStr ? new Date(storageDateStr) : new Date();
  const [filterDate, setFilterDate] = useState(storageDate);

  const monteurOptions = [
    {
      options: [
        {
          label: "Monteur 1",
          value: "Monteur 1",
        },
        {
          label: "Monteur 2",
          value: "Monteur 2",
        },
        {
          label: "Monteur 3",
          value: "Monteur 3",
        },
        {
          label: "Monteur 4",
          value: "Monteur 4",
        },
        {
          label: "Alle",
          value: "Alle",
        },
      ],
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: filteredReports.length,
    custom: true,
  };

  const handleSelectRow = row => {
    if (isAdmin()) props.history.push("/mieter/" + row.lfdNr);
  };

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: handleSelectRow,
  };

  useEffect(() => {
    getMieter();
  }, []);

  async function getMieter(date) {
    let result = await firebase.getMieter(date ?? filterDate);
    result = result.filter(r => !r.listeLetzerTermin);
    sortReports(result);
    setAllReports(result);
    setFilteredReports(filterReports(result));
    setLoading(false);
  }

  const filterReports = results => {
    const monteurFilter = localStorage.getItem("filterMonteur");
    if (!monteurFilter || monteurFilter === "Alle") return results;
    return results.filter(r => r.monteur === monteurFilter);
  };

  const handleSearch = async e => {
    e.preventDefault();
    setFilterDate();
    if (search == "") {
      setFilterDate(new Date());
      return getMieter(new Date());
    }
    let result = await firebase.getMieterBySearch(search, "mieter");

    if (!isAdmin()) result = result.filter(r => r.gbv == 5);

    result = result.filter(r => !r.listeLetzerTermin);
    sortReports(result);
    setAllReports(result);
    setFilteredReports(filterReports(result));
    setLoading(false);
  };

  const sortReports = reports => {
    reports.sort((a, b) => {
      const datumA = a.datumTausch3 ?? a.datumTausch2 ?? a.datumTausch;
      const datumB = b.datumTausch3 ?? b.datumTausch2 ?? b.datumTausch;
      const uhrzeitA = a.uhrzeitTausch3 ?? a.uhrzeitTausch2 ?? a.uhrzeitTausch;
      const uhrzeitB = b.uhrzeitTausch3 ?? b.uhrzeitTausch2 ?? b.uhrzeitTausch;

      const strA = datumA + uhrzeitA;
      const strB = datumB + uhrzeitB;
      let result = strA.localeCompare(strB);

      if (result === 0) result = a.lfdNr < b.lfdNr ? -1 : 1;

      return result;
    });
  };

  const handleDatumFilter = async e => {
    setSearch("");
    localStorage.setItem("filterDate", e[0]);
    setFilterDate(e[0]);
    getMieter(e[0]);
  };

  const handleMonteurFilter = async e => {
    localStorage.setItem("filterMonteur", e.value);
    const result = filterReports(allReports);
    setFilteredReports(result);
  };

  const rowClasses = (row, rowIndex) => {
    if (row.gbv === 5 && isAdmin()) return "warning-row";
  };
  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="lfdNr"
                      columns={mieterColumns || []}
                      data={filteredReports || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="lfdNr"
                          data={filteredReports}
                          columns={mieterColumns || []}
                          remote={{ filter: true }}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  sm="3"
                                  className="my-2 text-left d-none d-md-block"
                                >
                                  <Form>
                                    <Row>
                                      <Col>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="2">
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                                <Col sm="3"></Col>
                                <Col sm="3" className="my-2">
                                  <Flatpickr
                                    className="form-control d-block"
                                    lang="de"
                                    value={filterDate}
                                    multiple={false}
                                    onChange={handleDatumFilter}
                                    options={{
                                      dateFormat: "d.m.Y",
                                      locale: {
                                        ...Austria,
                                        firstDayOfWeek: 1,
                                      },
                                    }}
                                  />
                                </Col>
                                <Col sm="3" className="my-2">
                                  <Select
                                    options={monteurOptions}
                                    className="select2-selection"
                                    placeholder="auswählen"
                                    defaultValue={
                                      monteurFilter
                                        ? {
                                            label: monteurFilter,
                                            value: monteurFilter,
                                          }
                                        : undefined
                                    }
                                    onChange={handleMonteurFilter}
                                  />
                                </Col>
                                <Col
                                  sm="3"
                                  className="my-2 text-left d-md-none"
                                >
                                  <Form>
                                    <Row>
                                      <Col lg="12">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="12">
                                        <button
                                          type="submit"
                                          className="btn btn-primary w-100 mt-3"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  bordered={false}
                                  hover={true}
                                  selectRow={selectRow}
                                  rowClasses={rowClasses}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Reports);
