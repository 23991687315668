export const isAdmin = () => {
  if (!localStorage.getItem("authUser")) return false;
  const adminMails = process.env.REACT_APP_ADMIN_EMAILS.split(";");
  return adminMails.some(
    m => m === JSON.parse(localStorage.getItem("authUser")).email
  );
};

export const isGenossenschaft = () => {
  if (!localStorage.getItem("authUser")) return false;
  return (
    JSON.parse(localStorage.getItem("authUser")).email == "office@manilumi.com"
  );
};
