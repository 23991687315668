import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Dashboard
import Reports from "pages/Mieter";
import ReportDetails from "pages/MieterDetails";
import Import from "pages/Import";
import CompletedReports from "pages/Berichte";
import CompletedReportDetail from "pages/BerichtDetails";
import Pages404 from "pages/Authentication/pages-404";
import Objekte from "pages/Objekte";
import MieterExport from "pages/MieterExport";
import DritteTermineListe from "pages/DritteTermineListe";
import ObjektDetails from "pages/ObjektDetails";
import Verrechnung from "pages/Verrechnung";
import Abstellgenehmigung from "pages/Abstellgenehmigung";

const authProtectedRoutes = [
  { path: "/mieter", component: Reports },
  { path: "/objekte", component: Objekte },
  { path: "/objekte/:id", component: ObjektDetails },
  { path: "/berichte", component: CompletedReports },
  { path: "/berichte/:id", component: CompletedReportDetail },
  { path: "/import", component: Import },
  { path: "/mieter-export", component: MieterExport },
  { path: "/dritte-termine", component: DritteTermineListe },
  { path: "/mieter/:id", component: ReportDetails },
  { path: "/verrechnung", component: Verrechnung },
  { path: "/", exact: true, component: () => <Redirect to="/mieter" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/404", component: Pages404 },
  { path: "/abstellgenehmigung/:id", component: Abstellgenehmigung },
];

export { authProtectedRoutes, publicRoutes };
