import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import user1 from "../../assets/images/default-avatar.png";
import { connect } from "react-redux";
import { isAdmin, isGenossenschaft } from "helpers/misc_helper";

const getUserName = () => {
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj;
  }
};

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "Admin",
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const userData = getUserName();
    if (userData) {
      this.setState({ name: userData.username });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={user1}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            {!isGenossenschaft() && (
              <>
                {" "}
                <DropdownItem tag="a" href="/mieter">
                  <i className="bx bx-food-menu  font-size-17 align-middle me-1" />
                  Mieterliste
                </DropdownItem>
                <DropdownItem tag="a" href="/berichte">
                  <i className="bx bx-wrench font-size-17 align-middle me-1" />
                  Protokolle
                </DropdownItem>
              </>
            )}
            {isAdmin() && (
              <>
                <DropdownItem tag="a" href="/mieter-export">
                  <i className="bx bx-export  font-size-17 align-middle me-1" />
                  Mieter Export
                </DropdownItem>
              </>
            )}

            <DropdownItem tag="a" href="/dritte-termine">
              <i className="bx bx-timer   font-size-17 align-middle me-1" />
              3. Termine Liste
            </DropdownItem>

            <DropdownItem tag="a" href="/objekte">
              <i className="bx bx-building-house    font-size-17 align-middle me-1" />
              Objekte-Übersicht
            </DropdownItem>

            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Ausloggen</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
};

const mapStateToProps = state => {
  const { success } = state.Profile;
  return { success };
};

export default withRouter(connect(mapStateToProps, {})(ProfileMenu));
