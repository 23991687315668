import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { apiError, changePreloader, loginUser } from "../../store/actions";

// import images
import profile from "assets/images/profile-img.png";
import AuthErrorMapping from "./AuthErrorMapping";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { isGenossenschaft } from "helpers/misc_helper";

const Login = props => {
  const dispatch = useDispatch();
  const firebase = getFirebaseBackend();

  const { error } = useSelector(state => ({
    error: AuthErrorMapping(state.Login.error),
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Bitte E-Mail eintragen"),
      password: Yup.string().required("Bitte Passwort eintragen"),
    }),
    onSubmit: async values => {
      await firebase.loginUser(values.email, values.password);
      if (isGenossenschaft()) props.history.push("/objekte");
      else props.history.push("/mieter");
    },
  });

  useEffect(() => {
    if (error) {
      dispatch(changePreloader(false));
      setTimeout(() => {
        dispatch(apiError(""));
      }, 2000);
    }
  }, [error]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Willkommen zurück !</h5>
                        <p>Melden Sie sich an</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-4">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">E-Mail</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="email@manilumi.at"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Bitte Passwort eingeben"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Anmelden
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/passwort-vergessen" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Passwort vergessen ?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
