export const handleMieterExcelExport = async mieter => {
  let csvData = generateCSVData(mieter);
  var BOM = "\uFEFF";
  csvData = BOM + csvData;
  var blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  saveAs(blob, "mieterliste.csv");
};

const generateCSVData = mieter => {
  let csvData =
    "LfdNr;ObjNr.;WlfdNr.;Vorname;Nachname;Adresse;Ort;Datum;Uhrzeit;Notiz;Monteur;Erledigt (ja / nein);GBV;\n";

  mieter.forEach(
    m =>
      (csvData +=
        [
          m.lfdNr,
          m.objNr,
          m.lfd,
          m.vorName,
          m.famName,
          m.adresse,
          m.ort,
          m.datumTausch3 ?? m.datumTausch2 ?? m.datumTausch,
          m.uhrzeitTausch3 ?? m.uhrzeitTausch2 ?? m.uhrzeitTausch,
          m.notiz ?? "",
          m.monteur,
          "",
          m.gbv,
        ].join(";") + ";\n")
  );

  return csvData;
};
