import React from "react";
import { Col, Row, TabPane } from "reactstrap";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { roomOptions, statusOption } from "./setupOptions";
import { isAdmin } from "helpers/misc_helper";

const BrandmelderPane = ({ brandmelder, setBrandmelder }) => {
  const onAddFormRow = () => {
    const modifiedRows = [...brandmelder];
    const maxid = Math.max(...modifiedRows.map(o => o.id));
    modifiedRows.push({ id: maxid + 1 });
    setBrandmelder(modifiedRows);
  };

  const onDeleteBrandmelder = id => {
    const result = brandmelder.filter(x => x.id !== id);
    setBrandmelder(result);
  };

  return (
    <TabPane tabId={2} style={{ maxWidth: "1000px" }} className="mx-auto">
      <Row>
        <Col>
          <div>
            {brandmelder.map((row, key) => (
              <Row className="py-3 b-1 border" key={key}>
                <Col lg={5}>
                  <label htmlFor="name">Position</label>

                  <Select
                    options={roomOptions}
                    className="select2-selection"
                    placeholder="auswählen"
                    value={{ label: row.raum, value: row.raum }}
                    onChange={e => {
                      const values = [...brandmelder];
                      values.forEach(element => {
                        if (element.id === row.id) element.raum = e.value;
                      });
                      setBrandmelder(values);
                    }}
                  />
                </Col>

                <Col lg={5} className="mb-3">
                  <label htmlFor="email">Status</label>
                  <Select
                    options={statusOption}
                    className="select2-selection"
                    placeholder="auswählen"
                    value={{ label: row.status, value: row.status }}
                    onChange={e => {
                      const values = [...brandmelder];
                      values.forEach(element => {
                        if (element.id === row.id) element.status = e.value;
                      });
                      setBrandmelder(values);
                    }}
                  />
                </Col>

                <Col lg={2} className="align-self-center">
                  <div className="d-grid">
                    {isAdmin() && (
                      <input
                        type="button"
                        className="btn btn-primary"
                        defaultValue="entfernen"
                        onClick={() => onDeleteBrandmelder(row.id)}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </div>
          {isAdmin() && (
            <input
              type="button"
              className="btn btn-success mt-3 mt-lg-2"
              defaultValue="hinzufügen"
              onClick={() => onAddFormRow()}
            />
          )}
        </Col>
      </Row>
    </TabPane>
  );
};

export default BrandmelderPane;
