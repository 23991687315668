import { isAdmin } from "helpers/misc_helper";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const Overview = ({ report, reactivateBericht }) => {
  const [modal, setModal] = useState();

  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Überblick</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Laufnummer:</th>
                    <td scope="col">{report.lfdNr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Datum Tausch:</th>
                    <td scope="col">
                      {report.datumErledigt} {report.uhrzeitErledigt}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">1. Termin:</th>
                    <td scope="col">{report.datumTausch}</td>
                  </tr>
                  <tr>
                    <th scope="col">Objektnummer:</th>
                    <td scope="col">{report.objNr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Verrechnungsnr.:</th>
                    <td scope="col">{report.verrNr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Strasse:</th>
                    <td scope="col">{report.adresse}</td>
                  </tr>
                  <tr>
                    <th scope="col">Ort:</th>
                    <td scope="col">{report.plz + " " + report.ort}</td>
                  </tr>
                  <tr>
                    <th scope="col">Monteur:</th>
                    <td scope="col">{report.monteur}</td>
                  </tr>
                  <tr>
                    <th scope="col">Folge:</th>
                    <td scope="col">{report.folge}</td>
                  </tr>
                  <tr>
                    <th scope="col">Gbv:</th>
                    <td scope="col">{report.gbv}</td>
                  </tr>
                  <tr>
                    <th scope="col">SMS verschickt:</th>
                    <td scope="col">
                      {report.smsSent
                        ? moment(report.smsSent.toDate()).format(
                            "DD.MM.YYYY HH:mm"
                          )
                        : "-"}
                    </td>
                  </tr>
                  {report?.genehmigungSignatur && (
                    <tr>
                      <th scope="col">Signiert am:</th>
                      <td scope="col">
                        {moment(report.lastEdited.toDate()).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {isAdmin() && (
              <div className="hstack gap-2">
                <button
                  className="btn btn-soft-danger w-100"
                  onClick={_ => setModal(true)}
                >
                  erneut bearbeiten
                </button>
              </div>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Mieter/in</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Anrede:</th>
                    <td scope="col">{report.anrede ?? ""}</td>
                  </tr>
                  <tr>
                    <th scope="col">Nachname:</th>
                    <td scope="col">{report.famName ?? ""}</td>
                  </tr>
                  <tr>
                    <th scope="col">Vorname:</th>
                    <td scope="col">{report.vorName ?? ""}</td>
                  </tr>
                  <tr>
                    <th scope="col">Tel. Nr.:</th>
                    <td scope="col">{report.telnr}</td>
                  </tr>
                  <tr>
                    <th scope="col">E-Mail:</th>
                    <td scope="col">{report.email}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Modal
        isOpen={modal}
        centered
        toggle={() => {
          setModal();
        }}
      >
        <div className="modal-content">
          <ModalHeader toggle={() => setModal()} className="modal-header">
            Reaktivierung
          </ModalHeader>
          <ModalBody>
            <Row>
              <p>
                Bericht wieder reaktivieren?
                <br />
                <span className="text-danger">
                  Achtung - Brandmelder Infos und Unterschriften gehen verloren.
                </span>
              </p>
              <Col lg={12}>
                <div className="text-end">
                  <button
                    className="btn btn-danger me-1"
                    onClick={_ => reactivateBericht()}
                  >
                    Ja, reaktivieren
                  </button>
                  <button
                    className="btn btn-outline-secondary"
                    onClick={_ => setModal(false)}
                  >
                    Abbrechen
                  </button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Overview;
