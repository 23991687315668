//Import Breadcrumb

import React from "react";

const Loading = props => {
  return (
    <>
      {" "}
      {props.isLoading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      ) : (
        props.children
      )}
    </>
  );
};

export default Loading;
