import React from "react";
import { withRouter } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import { getFirebaseBackend } from "helpers/firebase_helper";

const Import = props => {
  const firebase = getFirebaseBackend();

  const handleImportObjekte = async () => {
    await firebase.importObjekte();
    alert("Import erfolgreich!");
  };

  const handleImportMieter = async () => {
    await firebase.importMieter();
    alert("Import erfolgreich!");
  };

  const handleChargeObjects = async () => {
    // const objects = [];
    // const objects = await firebase.getObjectsForCharge();
    // console.log(JSON.stringify(objects));
    chargeObjects();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Übersicht" breadcrumbItem="Berichte" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <button
                    className="btn btn-primary"
                    onClick={handleImportObjekte}
                  >
                    Import Objekte
                  </button>
                  <button
                    className="btn btn-primary mx-4"
                    onClick={handleImportMieter}
                  >
                    Import Mieter
                  </button>

                  <button
                    className="btn btn-primary mx-4"
                    onClick={handleChargeObjects}
                  >
                    Verrechnung
                  </button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Import);
