export const mieterliste = [
  {
    lfdNr: "20852",
    objNr: "3031",
    lfd: "",
    folge: "",
    verrNr: "",
    anrede: "Herr ",
    vorName: "Ernst und Silvia ",
    famName: "Spanring",
    adresse: "Seegasse 37",
    plz: "9020",
    ort: "Klagenfurt",
    gbv: "5",
    email: "",
    telnr: "0650/2475061 ",
  },
  {
    lfdNr: "20853",
    objNr: "20541",
    lfd: "",
    folge: "",
    verrNr: "",
    anrede: "Herr ",
    vorName: "Patrick ",
    famName: "Hillepold",
    adresse: "Prof. Deutsch-Gasse 9",
    plz: "9020",
    ort: "Klagenfurt",
    gbv: "5",
    email: "",
    telnr: "0699/11424001",
  },
  {
    lfdNr: "20854",
    objNr: "21041",
    lfd: "",
    folge: "",
    verrNr: "",
    anrede: "Herr ",
    vorName: "Robert",
    famName: "Oberrauner ",
    adresse: "Schächtestraße 34/E/3",
    plz: "9500",
    ort: "Villach ",
    gbv: "5",
    email: "",
    telnr: "0650/2836353",
  },
];
