import { mieterliste } from "assets/data/mieter";
import { objektListe } from "assets/data/objekte";
import firebase from "firebase/compat/app";

// Add the Firebase products that you want to use
import "firebase/compat/auth";
import "firebase/compat/firestore";
import moment from "moment";
import { isAdmin } from "./misc_helper";

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      firebase.initializeApp(firebaseConfig);
      firebase.auth().onAuthStateChanged(user => {
        if (user) localStorage.setItem("authUser", JSON.stringify(user));
        else localStorage.removeItem("authUser");
      });
    }
  }

  getMieter = async date => {
    const datumReports = await this.searchByDate("datumTausch", date);
    const datum2Reports = await this.searchByDate("datumTausch2", date);
    const datum3Reports = await this.searchByDate("datumTausch3", date);

    return [...datumReports, ...datum2Reports, ...datum3Reports];
  };

  searchByDate = async (field, date) => {
    const reports = [];
    let ref = firebase
      .firestore()
      .collection("mieter")
      .where(field, "==", moment(date).format("DD.MM.YYYY"));

    if (!isAdmin()) ref = ref.where("gbv", "==", 5);

    const querySnapshot = await ref.get();

    querySnapshot.forEach(doc => {
      const newReport = { lfdNr: doc.id, ...doc.data() };
      if (field === "datumTausch" && !newReport.datumTausch2)
        reports.push(newReport);
      else if (field === "datumTausch2" && !newReport.datumTausch3)
        reports.push(newReport);
      else if (field === "datumTausch3") reports.push(newReport);
    });
    return reports;
  };

  getLetzteTermine = async _ => {
    const letzteTermine = [];

    let querySnapshot = await firebase
      .firestore()
      .collection("mieter")
      .where("listeLetzerTermin", "==", true)
      .get();

    querySnapshot.forEach(doc => {
      const newReport = { lfdNr: doc.id, ...doc.data() };
      letzteTermine.push(newReport);
    });

    return letzteTermine;
  };

  getBerichte = async date => {
    const protocols = [];

    let ref = firebase
      .firestore()
      .collection("berichte")
      .where("datumErledigt", "==", moment(date).format("DD.MM.YYYY"));

    if (!isAdmin()) ref = ref.where("gbv", "==", 5);

    let querySnapshot = await ref.get();

    querySnapshot.forEach(doc => {
      const newReport = { lfdNr: doc.id, ...doc.data() };
      protocols.push(newReport);
    });

    return protocols;
  };

  getMieterBySearch = async (search, collection) => {
    var strlength = search.length;
    var strFrontCode = search.slice(0, strlength - 1);
    var strEndCode = search.slice(strlength - 1, search.length);

    var startcode = search;
    var endcode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
    let doc = await firebase
      .firestore()
      .collection(collection)
      .doc(search)
      .get();

    if (doc.exists) {
      var foundDoc = { lfdNr: doc.id, ...doc.data() };
      const result2 = await this.searchCollectionByField(
        startcode,
        endcode,
        "verrNr",
        collection
      );
      return [...result2.filter(r => r.lfdNr !== foundDoc.lfdNr), foundDoc];
    } else {
      const result1 = await this.searchCollectionByField(
        startcode,
        endcode,
        "adresse",
        collection
      );
      const result2 = await this.searchCollectionByField(
        startcode,
        endcode,
        "famName",
        collection
      );
      const result3 = await this.searchCollectionByField(
        startcode,
        endcode,
        "verrNr",
        collection
      );

      const result4 = await this.searchCollectionByField(
        startcode,
        endcode,
        "objNr",
        collection
      );

      var result = [...result1];

      result2.forEach(r => {
        if (!result.find(o => o.lfdNr === r.lfdNr)) result.push(r);
      });
      result3.forEach(r => {
        if (!result.find(o => o.lfdNr === r.lfdNr)) result.push(r);
      });

      result4.forEach(r => {
        if (!result.find(o => o.lfdNr === r.lfdNr)) result.push(r);
      });

      return result;
    }
  };

  searchCollectionByField = async (startcode, endcode, field, collection) => {
    const documents = [];
    const querySnapshot = await firebase
      .firestore()
      .collection(collection)
      .where(field, ">=", startcode)
      .where(field, "<", endcode)
      .limit(200)
      .get();

    querySnapshot.forEach(doc => {
      if (documents.filter(r => r.lfdNr === doc.id).length === 0)
        documents.push({ id: doc.id, ...doc.data() });
    });

    return documents;
  };

  getObjekteBySearch = async (search, collection) => {
    var strlength = search.length;
    var strFrontCode = search.slice(0, strlength - 1);
    var strEndCode = search.slice(strlength - 1, search.length);

    var startcode = search;
    var endcode =
      strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

    const result1 = await this.searchCollectionByField(
      startcode,
      endcode,
      "bezeichnung",
      collection
    );
    const result2 = await this.searchCollectionByField(
      startcode,
      endcode,
      "objnr",
      collection
    );

    var result = [];

    for (let i = 0; i < result1.length; i++) {
      const element = result1[i];
      result1[i].berichte = await this.getObjektBerichte(
        element.objnr,
        element.bezeichnung
      );
      result.push(result1[i]);
    }

    for (let i = 0; i < result2.length; i++) {
      const element = result2[i];
      if (
        !result.find(
          o =>
            o.objnr === element.objnr && o.bezeichnung === element.bezeichnung
        )
      ) {
        result2[i].berichte = await this.getObjektBerichte(
          element.objnr,
          element.bezeichnung
        );
        result.push(result2[i]);
      }
    }

    return result;
  };

  getMieterByID = async id => {
    const doc = await firebase.firestore().collection("mieter").doc(id).get();

    if (!doc.exists) return (window.location.href = "/404");

    return { ...doc.data(), lfdNr: doc.id };
  };

  getObjektByID = async id => {
    const doc = await firebase.firestore().collection("objekte").doc(id).get();

    if (!doc.exists) return (window.location.href = "/404");

    let objekt = { ...doc.data(), id: doc.id };

    objekt.termine = await this.getTermineVonObjekt(
      objekt.objnr,
      objekt.bezeichnung
    );

    return objekt;
  };

  getTermineVonObjekt = async (objnr, adresse) => {
    const berichte = await this.getBerichteFromObjekt(objnr, adresse);
    const mieter = await this.getMieterFromObjekt(objnr, adresse);
    return [...berichte, ...mieter];
  };

  getBerichteFromObjekt = async (objnr, adresse) => {
    const documents = [];
    const querySnapshot = await firebase
      .firestore()
      .collection("berichte")
      .where("objNr", "==", objnr)
      .limit(200)
      .get();

    querySnapshot.forEach(doc => {
      const obj = { lfdNr: doc.id, ...doc.data() };
      if (obj.adresse.startsWith(adresse)) documents.push(obj);
    });

    return documents;
  };

  getMieterFromObjekt = async (objnr, adresse) => {
    const documents = [];
    const querySnapshot = await firebase
      .firestore()
      .collection("mieter")
      .where("objNr", "==", objnr)
      .limit(200)
      .get();

    querySnapshot.forEach(doc => {
      const obj = { lfdNr: doc.id, ...doc.data() };

      if (obj.adresse.startsWith(adresse)) documents.push(obj);
    });

    return documents;
  };

  getBerichtByID = async id => {
    const doc = await firebase.firestore().collection("berichte").doc(id).get();

    if (!doc.exists) return (window.location.href = "/404");

    return { ...doc.data(), lfdNr: doc.id };
  };

  updateMieter = async mieter => {
    Object.keys(mieter).forEach(
      k => (mieter[k] == null || mieter[k] == "") && delete mieter[k]
    );

    await firebase
      .firestore()
      .collection("mieter")
      .doc(mieter.lfdNr.toString())
      .set({ ...mieter, lastEdited: new Date() });
  };

  updateBericht = async report => {
    await firebase
      .firestore()
      .collection("berichte")
      .doc(report.lfdNr.toString())
      .set({ ...report, lastEdited: new Date() });
  };

  updateObjekt = async objekt => {
    await firebase
      .firestore()
      .collection("objekte")
      .doc(objekt.id)
      .update({ bezeichnung: objekt.bezeichnung, anzWE: objekt.anzWE });
  };

  updateObjekt = async objekt => {
    await firebase
      .firestore()
      .collection("objekte")
      .doc(objekt.id)
      .update({ bezeichnung: objekt.bezeichnung, anzWE: objekt.anzWE });
  };

  createBericht = async report => {
    Object.keys(report).forEach(
      k => (report[k] == null || report[k] == "") && delete report[k]
    );

    await firebase
      .firestore()
      .collection("berichte")
      .doc(report.lfdNr.toString())
      .set({
        ...report,
        datumErledigt: moment(new Date()).format("DD.MM.YYYY"),
        uhrzeitErledigt: moment(new Date()).format("hh:mm"),
      });

    await firebase
      .firestore()
      .collection("mieter")
      .doc(report.lfdNr.toString())
      .delete();
  };

  deleteMieter = async lfdNr => {
    if (lfdNr)
      await firebase.firestore().collection("mieter").doc(lfdNr).delete();
  };

  reactivateBericht = async report => {
    report.datumErledigt = null;
    Object.keys(report).forEach(
      k => (report[k] == null || report[k] == "") && delete report[k]
    );

    await firebase
      .firestore()
      .collection("mieter")
      .doc(report.lfdNr.toString())
      .set({
        ...report,
      });

    await firebase
      .firestore()
      .collection("berichte")
      .doc(report.lfdNr.toString())
      .delete();
  };

  importMieter = async _ => {
    for (let idx = 0; idx < mieterliste.length; idx++) {
      const mieter = mieterliste[idx];

      try {
        let newMieter = this.mieterToFirestoreMieter(mieter);

        newMieter = await this.setObjektProperties(newMieter);

        if (newMieter?.datumTausch)
          await firebase
            .firestore()
            .collection("mieter")
            .doc("" + mieter.lfdNr)
            .set(newMieter);
        else console.log(mieter.lfdNr + " nicht importiert");
      } catch (error) {
        console.log(mieter);
        console.log(mieter.lfdNr, error);
      }
    }
  };

  // updateMieter = async _ => {
  //   const mieter = [];
  //   let querySnapshot = await firebase
  //     .firestore()
  //     .collection("mieter")
  //     .where("lfd", "==", NaN)
  //     .get();

  //   querySnapshot.forEach(doc => {
  //     mieter.push({ id: doc.id, ...doc.data() });
  //   });

  //   console.log(mieter);
  //   for (let i = 0; i < mieter.length; i++) {
  //     const element = mieter[i];

  //     await firebase
  //       .firestore()
  //       .collection("mieter")
  //       .doc(element.id)
  //       .update({ lfd: 0 });
  //   }
  // };

  getObjekteByDate = async date => {
    const objekte = [];
    let query = firebase
      .firestore()
      .collection("objekte")
      .where("datumTausch", "==", date);

    if (!isAdmin()) query = query.where("gbv", "==", 5);

    const querySnapshot = await query.get();

    querySnapshot.forEach(doc => {
      objekte.push({ id: doc.id, ...doc.data() });
    });

    return objekte;
  };

  getObjects = async date => {
    const objects = await this.getObjekteByDate(date);

    for (let i = 0; i < objects.length; i++) {
      const element = objects[i];
      const berichte = await this.getObjektBerichte(
        element.objnr,
        element.bezeichnung
      );
      element.berichte = berichte;
    }

    return objects;
  };

  getObjektBerichte = async (objnr, adresse) => {
    const berichte = [];
    const querySnapshot = await firebase
      .firestore()
      .collection("berichte")
      .where("objNr", "==", objnr)
      .get();

    querySnapshot.forEach(doc => {
      const bericht = { id: doc.id, ...doc.data() };
      if (bericht.adresse.startsWith(adresse)) berichte.push(bericht);
    });

    return berichte;
  };

  setObjektProperties = async mieter => {
    const objects = [];
    const querySnapshot = await firebase
      .firestore()
      .collection("objekte")
      .where("objnr", "==", mieter.objNr)
      .get();

    querySnapshot.forEach(doc => {
      objects.push({ id: doc.id, ...doc.data() });
    });

    if (objects.length > 0) {
      let objekt = objects[0];
      if (objects.length > 1) {
        const filterd = objects.filter(o =>
          mieter.adresse.startsWith(o.bezeichnung)
        );
        if (filterd.length > 0) objekt = filterd[0];
        else
          return console.log(
            mieter.lfdNr + " -> " + mieter.objNr + " is nicht zuordenbar"
          );
      }

      if (objekt) {
        mieter.hvAbteilung = objekt.hvAbteilung ?? "";
        mieter.bezeichnungObjekt = objekt.bezeichnung;
        mieter.datumTausch = moment(objekt.datumTausch.toDate()).format(
          "DD.MM.YYYY"
        );
        mieter.uhrzeitTausch = objekt.uhrzeitTausch;
        mieter.monteur = objekt.monteur;
      }
    }
    return mieter;
  };

  mieterToFirestoreMieter = element => {
    const neuesObjekt = {
      lfdNr: parseInt(element.lfdNr.trim()),
      objNr: element.objNr.trim(),
      lfd: parseInt(element.lfd ? element.lfd.trim() : "0"),
      folge: element.folge ? parseInt(element.folge.trim()) : 1,
      verrNr: element.verrNr?.trim()?.replace("Verr.Nr.: ", ""),
      anrede: element.anrede?.trim(),
      famName: element.famName?.trim(),
      vorName: element.vorName?.trim(),
      adresse: element.adresse?.trim(),
      plz: element.plz?.trim(),
      ort: element.ort?.trim(),
      gbv: parseInt(element.gbv?.trim()),
      rwm: element.rwm?.trim(),
      email: element.email?.trim(),
      telnr: element.telnr?.trim(),
      eigentuemer: element.eigentuemer === 1,
    };
    return Object.fromEntries(
      Object.entries(neuesObjekt).filter(([_, v]) => v != null)
    );
  };

  importObjekte = async _ => {
    for (let idx = 0; idx < objektListe.length; idx++) {
      const objekt = objektListe[idx];

      let newObjekt = this.objektToFirestoreObjekt(objekt);
      console.log(newObjekt);

      await firebase.firestore().collection("objekte").doc().set(newObjekt);
    }
  };

  objektToFirestoreObjekt = element => {
    const neuesObjekt = {
      objnr: element.objnr.trim(),
      bezeichnung: element.bezeichnung.trim(),
      plz: element.plz.trim(),
      ort: element.ort.trim(),
      gbv: parseInt(element.gbv.trim()),
      gbvName: element.gbvName.trim(),
      fvw: parseInt(element.fvw.trim()),
      hvAbteilung: element.hvAbteilung.trim(),
      anzWE: parseInt(element.anzWE.trim()),
      anzRWM: parseInt(element.anzRWM.trim()),
      datumTausch: moment(element.datumTausch.trim(), "MM/DD/YY").toDate(),
      uhrzeitTausch: element.uhrzeitTausch.trim(),
      monteur: this.mapMontuer(element.monteur),
      verrechnet: 0,
      km: element.km,
    };
    return Object.fromEntries(
      Object.entries(neuesObjekt).filter(([_, v]) => v != null)
    );
  };

  mapMontuer = monteurString => {
    monteurString = monteurString.trim();

    switch (monteurString) {
      case "M1":
        return "Monteur 1";
      case "M2":
        return "Monteur 2";
      case "M3":
        return "Monteur 3";
      case "M4":
        return "Monteur 4";
    }
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          () => {
            resolve(firebase.auth().currentUser);
          },
          error => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = user => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend;
};

export { initFirebaseBackend, getFirebaseBackend };
