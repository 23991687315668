import { post } from "./api_helper";

export async function createInvoicesObjects(objNrs, km) {
  return await post("/verrechnung-objekte", {
    objNrs: objNrs,
    km: km,
  });
}

export async function createInvoicesMieter(lfdNrs, strafbetrag) {
  return await post("/verrechnung-mieter", {
    lfdNrs,
    strafbetrag,
  });
}

export async function printBericht(bericht) {
  return await post("/berichtToPDF", bericht, {
    responseType: "blob",
  });
}

export async function sendSMS(lfdNr, telNr) {
  return await post("/send-SMS", { lfdNr, telNr });
}
