import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const Logout = props => {
  useEffect(() => {
    localStorage.removeItem("authuser");

    props.history.push("/login");
  }, []);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
