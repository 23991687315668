import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import "./tablestyle.css";
import { isAdmin } from "helpers/misc_helper";

const Objekte = props => {
  const node = React.createRef();
  const firebase = getFirebaseBackend();
  const [allObjekte, setAllObjekte] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(
    localStorage.getItem("objekteSuche") ?? ""
  );
  const storageDateStr = localStorage.getItem("filterDate");
  const storageDate = storageDateStr ? new Date(storageDateStr) : new Date();
  const [filterDate, setFilterDate] = useState(storageDate);
  const rowClasses = row => {
    if (row.anzWE === row.berichte?.length) return "success-row";

    if (row.verrechnet != 0) return "warning-row";

    return "danger-row";
  };

  const columns = [
    {
      dataField: "objnr",
      text: "ObjNr.",
      sort: true,
    },
    {
      dataField: "anzWE",
      text: "Anzahl WE.",
      sort: true,
    },
    {
      dataField: "berichte",
      text: "Fertige Berichte",
      sort: true,
      formatter: (_, row) => row.berichte?.length ?? 0,
    },
    {
      dataField: "anzRWM",
      text: "Anzahl RWM.",
      sort: true,
    },
    {
      dataField: "anrede",
      text: "anz RWM.",
      sort: true,
      formatter: (_, row) => {
        let count = 0;

        if (row.berichte)
          row.berichte.forEach(b => (count += b.brandmelder?.length ?? 0));

        return count;
      },
    },
    {
      dataField: "bezeichnung",
      text: "Adresse",
      sort: true,
    },
    {
      dataField: "monteur",
      text: "Monteur",
      sort: true,
    },
  ];

  const pageOptions = {
    sizePerPage: 100,
    totalSize: allObjekte.length,
    custom: true,
  };

  const handleSelectRow = row => {
    props.history.push("/objekte/" + row.id);
  };

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: handleSelectRow,
  };

  useEffect(() => {
    if (search) handleSearch();
    else getObjekte();
  }, []);

  async function getObjekte(date) {
    const result = await firebase.getObjects(date ?? filterDate);
    sortAndFilterObjekte(result);
    setAllObjekte(result);
    setLoading(false);
  }

  const handleSearch = async e => {
    if (e) e.preventDefault();

    if (search == "") {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      setFilterDate(today);
      localStorage.setItem("objekteSuche", "");
      return getObjekte(today);
    }
    let result = await firebase.getObjekteBySearch(search, "objekte");
    localStorage.setItem("objekteSuche", search);
    result = sortAndFilterObjekte(result);
    console.log(result);
    setAllObjekte(result);
    setFilterDate();
    setLoading(false);
  };

  const sortAndFilterObjekte = reports => {
    if (!isAdmin()) reports = reports.filter(r => r.gbv === 5);

    reports.sort((a, b) => {
      let result = a.uhrzeitTausch.localeCompare(b.uhrzeitTausch);

      if (result === 0) result = a.lfdNr < b.lfdNr ? -1 : 1;

      return result;
    });
    return reports;
  };

  const handleDatumFilter = async e => {
    setSearch("");
    localStorage.setItem("filterDate", e[0]);
    setFilterDate(e[0]);
    getObjekte(e[0]);
  };

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="id"
                      columns={columns || []}
                      data={allObjekte || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={allObjekte}
                          columns={columns || []}
                          remote={{ filter: true }}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  sm="3"
                                  className="my-2 text-left d-none d-md-block"
                                >
                                  <Form>
                                    <Row>
                                      <Col>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="2">
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                                <Col sm="6"></Col>
                                <Col sm="3" className="my-2">
                                  <Flatpickr
                                    className="form-control d-block"
                                    lang="de"
                                    value={filterDate}
                                    multiple={false}
                                    onChange={handleDatumFilter}
                                    options={{
                                      dateFormat: "d.m.Y",
                                      locale: {
                                        ...Austria,
                                        firstDayOfWeek: 1,
                                      },
                                    }}
                                  />
                                </Col>

                                <Col
                                  sm="3"
                                  className="my-2 text-left d-md-none"
                                >
                                  <Form>
                                    <Row>
                                      <Col lg="12">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="12">
                                        <button
                                          type="submit"
                                          className="btn btn-primary w-100 mt-3"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  bordered={false}
                                  hover={false}
                                  rowClasses={rowClasses}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Objekte);
