import React, { useEffect, useState } from "react";

import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import Breadcrumbs from "components/Common/Breadcrumb";
import Overview from "./Overview";
import DetailsSection from "./DetailsSection";

const FormWizard = () => {
  const firebase = getFirebaseBackend();
  const { id } = useParams();
  const [report, setReport] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBerichtByID();
  }, [id]);

  async function getBerichtByID() {
    setLoading(true);
    const result = await firebase.getBerichtByID(id);
    setReport(result);
    setLoading(false);
  }

  async function reactivateBericht() {
    setLoading(true);
    await firebase.reactivateBericht(report);
    window.location.href = `/mieter/${report.lfdNr}`;
    setLoading(false);
  }

  return (
    <React.Fragment>
      <div className="page-content" id="your-id">
        <Loading isLoading={loading}>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Protokolle" breadcrumbItem="Bericht" />

            <Row>
              <Overview report={report} reactivateBericht={reactivateBericht} />
              <DetailsSection report={report} setLoading={setLoading} />
            </Row>
          </Container>
        </Loading>
      </div>
    </React.Fragment>
  );
};

export default FormWizard;
