import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";
import { isAdmin } from "helpers/misc_helper";

const DetailsSection = ({ objekt, history }) => {
  const node = React.createRef();

  const columns = [
    {
      dataField: "lfdNr",
      text: "LfdNr",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.lfdNr}
        </Link>
      ),
    },

    {
      dataField: "lfd",
      text: "WLfdNr",
      sort: true,
    },

    {
      dataField: "famName",
      text: "Mieter",
      formatter: (cellContent, row) => (
        <>{row.famName ?? "" + " " + row.vorName ?? ""}</>
      ),
    },
    {
      dataField: "datumTausch",
      text: "Termin",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {row.datumTausch3 ?? row.datumTausch2 ?? row.datumTausch},{" "}
          {row.uhrzeitTausch3 ?? row.uhrzeitTausch2 ?? row.uhrzeitTausch}
        </>
      ),
    },
    {
      dataField: "notiz",
      text: "Notiz",
      sort: true,
    },
    {
      dataField: "anzRWM",
      text: "Anzahl BM",
      formatter: (cellContent, row) => row.brandmelder?.length ?? 0,
    },
    {
      dataField: "brandmelder",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => (
        <span
          className={
            "badge badge-pill p-2 font-size-12 badge-soft-" +
            (row.datumErledigt ? "success" : "warning")
          }
        >
          {row.datumErledigt ? "erledigt" : "offen"}
        </span>
      ),
    },
    {
      dataField: "monteur",
      text: "Monteur",
      sort: true,
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: objekt.termine.length,
    custom: true,
  };

  const handleSelectRow = row => {
    if (row.datumErledigt) history.push("/berichte/" + row.lfdNr);
    else if (isAdmin()) history.push("/mieter/" + row.lfdNr);
  };

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: handleSelectRow,
  };

  return (
    <React.Fragment>
      <Col xl={9}>
        <Card>
          <CardBody className="border-bottom">
            <PaginationProvider
              pagination={paginationFactory(pageOptions || [])}
              keyField="lfdNr"
              columns={columns || []}
              data={objekt.termine || []}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="lfdNr"
                  data={objekt.termine}
                  columns={columns || []}
                  remote={{ filter: true }}
                  bootstrap4
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                          bordered={false}
                          hover={true}
                          selectRow={selectRow}
                          classes={
                            "table align-middle table-nowrap table-check"
                          }
                          headerWrapperClasses={"table-light"}
                          ref={node}
                        />
                      </div>
                      <div className="pagination pagination-rounded justify-content-end mb-2">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default withRouter(DetailsSection);
