export default error => {
  switch (error) {
    case "":
      return ""
    case undefined:
      return ""
    case null:
      return ""
    case "Firebase: The email address is already in use by another account. (auth/email-already-in-use).":
      return "E-Mail Adresse ist schon in Verwendung!"
    case "Firebase: Password should be at least 6 characters (auth/weak-password).":
      return "Das Passwort muss mindestens 6 Zeichen beinhalten!"
    case "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password).":
      return "E-Mail oder Passwort ist inkorrekt. Bitte erneut versuchen"
    case "Firebase: The email address is badly formatted. (auth/invalid-email).":
      return "E-Mail oder Passwort ist inkorrekt. Bitte erneut versuchen"
    case "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found).":
      return "E-Mail oder Passwort ist inkorrekt. Bitte erneut versuchen"
    case "Firebase: This operation is sensitive and requires recent authentication. Log in again before retrying this request. (auth/requires-recent-login).":
      return "Bitte loggen Sie sich erneut ein, damit Sie Ihr Passwort ändern können."
    default:
      return "Ein unerwarteter Fehler ist aufgetreten!"
  }
}
