import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Input, Row } from "reactstrap";
import { getFirebaseBackend } from "helpers/firebase_helper";
import Loading from "components/VerticalLayout/Loading";

const Protocolls = props => {
  const node = React.createRef();
  const firebase = getFirebaseBackend();
  const [allReports, setAllReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const storageDateStr = localStorage.getItem("filterDate");
  const storageDate = storageDateStr ? new Date(storageDateStr) : new Date();
  const [filterDate, setFilterDate] = useState(storageDate);

  const columns = [
    {
      dataField: "lfdNr",
      text: "LfdNr",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.lfdNr}
        </Link>
      ),
    },
    {
      dataField: "objNr",
      text: "ObjNr.",
      sort: true,
    },
    {
      dataField: "lfd",
      text: "WLfdNr",
      sort: true,
    },
    {
      dataField: "adresse",
      text: "Adresse",
      sort: true,
    },
    {
      dataField: "famName",
      text: "Mieter",
      formatter: (cellContent, row) => (
        <>{(row.famName ?? "") + " " + (row.vorName ?? "")}</>
      ),
    },
    {
      dataField: "datumTausch",
      text: "Termin",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          {row.datumTausch3 ?? row.datumTausch2 ?? row.datumTausch},{" "}
          {row.uhrzeitTausch3 ?? row.uhrzeitTausch2 ?? row.uhrzeitTausch}
        </>
      ),
    },
    {
      dataField: "notiz",
      text: "Notiz",
      sort: true,
    },
    {
      dataField: "brandmelder",
      text: "Anzahl BM",
      formatter: (cellContent, row) => row.brandmelder?.length ?? 0,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) => (
        <span
          className={
            "badge badge-pill p-2 font-size-12 badge-soft-" +
            (row.brandmelder?.length > 0 ? "success" : "warning")
          }
        >
          {row.brandmelder?.length > 0 ? "erledigt" : "offen"}
        </span>
      ),
    },
    {
      dataField: "monteur",
      text: "Monteur",
      sort: true,
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: allReports.length,
    custom: true,
  };

  const handleSelectRow = row => {
    props.history.push("/berichte/" + row.lfdNr);
  };

  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
    onSelect: handleSelectRow,
  };

  useEffect(() => {
    getBerichte();
  }, []);

  async function getBerichte(date) {
    const result = await firebase.getBerichte(date ?? filterDate);
    sortReports(result);
    setAllReports(result);
    setLoading(false);
  }

  const handleSearch = async e => {
    e.preventDefault();
    setFilterDate();
    if (search == "") {
      setFilterDate(new Date());
      return getBerichte(new Date());
    }
    const result = await firebase.getMieterBySearch(search, "berichte");
    sortReports(result);
    setAllReports(result);
    setLoading(false);
  };

  const sortReports = reports => {
    reports.sort((a, b) => {
      const datumA = a.datumTausch3 ?? a.datumTausch2 ?? a.datumTausch;
      const datumB = b.datumTausch3 ?? b.datumTausch2 ?? b.datumTausch;
      const uhrzeitA = a.uhrzeitTausch3 ?? a.uhrzeitTausch2 ?? a.uhrzeitTausch;
      const uhrzeitB = b.uhrzeitTausch3 ?? b.uhrzeitTausch2 ?? b.uhrzeitTausch;

      const strA = datumA + uhrzeitA;
      const strB = datumB + uhrzeitB;
      let result = strA.localeCompare(strB);

      if (result === 0) result = a.lfdNr < b.lfdNr ? -1 : 1;

      return result;
    });
  };

  const handleDatumFilter = async e => {
    setSearch("");
    localStorage.setItem("filterDate", e[0]);
    setFilterDate(e[0]);
    getBerichte(e[0]);
  };

  return (
    <React.Fragment>
      <div className="page-content px-0">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Loading isLoading={loading}>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="lfdNr"
                      columns={columns || []}
                      data={allReports || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="lfdNr"
                          data={allReports}
                          columns={columns || []}
                          remote={{ filter: true }}
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  sm="3"
                                  className="my-2 text-left d-none d-md-block"
                                >
                                  <Form>
                                    <Row>
                                      <Col>
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="2">
                                        <button
                                          type="submit"
                                          className="btn btn-primary"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                                <Col sm="6"></Col>
                                <Col sm="3" className="my-2">
                                  <Flatpickr
                                    className="form-control d-block"
                                    lang="de"
                                    value={filterDate}
                                    multiple={false}
                                    onChange={handleDatumFilter}
                                    options={{
                                      dateFormat: "d.m.Y",
                                      locale: {
                                        ...Austria,
                                        firstDayOfWeek: 1,
                                      },
                                    }}
                                  />
                                </Col>

                                <Col
                                  sm="3"
                                  className="my-2 text-left d-md-none"
                                >
                                  <Form>
                                    <Row>
                                      <Col lg="12">
                                        <Input
                                          type="text"
                                          className="form-control"
                                          value={search}
                                          placeholder="Suche..."
                                          onChange={e =>
                                            setSearch(e.target.value)
                                          }
                                        />
                                      </Col>
                                      <Col lg="12">
                                        <button
                                          type="submit"
                                          className="btn btn-primary w-100 mt-3"
                                          onClick={handleSearch}
                                        >
                                          Suche
                                        </button>
                                      </Col>
                                    </Row>
                                  </Form>
                                </Col>
                              </Row>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  bordered={false}
                                  hover={true}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Loading>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Protocolls);
