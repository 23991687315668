import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Austria } from "flatpickr/dist/l10n/at.js";
import moment from "moment";
import { getFirebaseBackend } from "helpers/firebase_helper";
import { useDispatch } from "react-redux";
import { changePreloader } from "store/actions";
import Loading from "components/VerticalLayout/Loading";
import ObjectDetailsPane from "./ObjectDetailsPane";
import BrandmelderPane from "./BrandmelderPane";
import { brandmelderInitialValues } from "./setupValues";
import MeldungenPane from "./MeldungenPane";
import SignutarePane from "./SignaturePane";

const FormWizard = () => {
  const firebase = getFirebaseBackend();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [report, setReport] = useState();
  const [modal, setModal] = useState(false);
  const [modalLastTermin, setModalLastTermin] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalLastTerminEnfernen, setModalLastTerminEnfernen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [brandmelder, setBrandmelder] = useState(brandmelderInitialValues);
  const mieterSignatureRef = useRef();
  const monteurSignatureRef = useRef();
  const [error, setError] = useState();

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 5) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  useEffect(() => {
    getMieterByID();
  }, [id]);

  async function getMieterByID() {
    dispatch(changePreloader(true));
    const result = await firebase.getMieterByID(id);

    const datumTausch = moment(
      result.datumTausch2 ?? result.datumTausch,
      "DD.MM.YYYY"
    ).toDate();
    setSelectedDate(new Date(datumTausch.getTime() + 7 * 24 * 60 * 60 * 1000));
    setSelectedTime(datumTausch.getDay() === 5 ? "10:00-12:00" : "12:30-15:00");

    if (result.brandmelder) setBrandmelder(result.brandmelder);

    setReport(result);
    setLoading(false);
  }

  const handleSubmit = async () => {
    setLoading(true);

    if (brandmelder !== brandmelderInitialValues)
      report.brandmelder = brandmelder.filter(b => b.status);

    await firebase.updateMieter(report);

    setactiveTab(5);
    setLoading(false);
  };

  const handleDone = async () => {
    setLoading(true);

    if (brandmelder !== brandmelderInitialValues)
      report.brandmelder = brandmelder.filter(b => b.status);
    else return displayError("Bitte Brandmelder befüllen!");

    if (
      mieterSignatureRef.current.isEmpty() ||
      monteurSignatureRef.current.isEmpty()
    )
      return displayError("Bitte Unterschriften ausfüllen!");

    report.mieterSignature = mieterSignatureRef.current.toDataURL();
    report.monteurSignature = monteurSignatureRef.current.toDataURL();

    await firebase.createBericht(report);
    setactiveTab(5);
    setLoading(false);
  };

  const displayError = error => {
    setError(error);
    setLoading(false);
  };

  const handleNeuerTermin = async () => {
    setLoading(true);
    const newDate = moment(selectedDate).format("DD.MM.YYYY");
    const newReport = {
      ...report,
      datumTausch2: newDate,
      uhrzeitTausch2: selectedTime,
    };
    setReport(newReport);
    await firebase.updateMieter(newReport);
    setModal(false);
    setactiveTab(5);
    setLoading(false);
  };

  const handleLastTermin = async () => {
    setLoading(true);
    const newReport = {
      ...report,
      listeLetzerTermin: true,
      letzterTerminZeit: moment(new Date()).format("DD.MM.YYYY HH:mm"),
    };

    setReport(newReport);
    await firebase.updateMieter(newReport);
    setModalLastTermin(false);
    setactiveTab(5);
    setLoading(false);
  };

  const handleLastTerminEntfernen = async () => {
    setLoading(true);
    const newReport = {
      ...report,
      listeLetzerTermin: false,
    };

    setReport(newReport);
    await firebase.updateMieter(newReport);
    setModalLastTerminEnfernen(false);
    setactiveTab(5);
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await firebase.deleteMieter(report.lfdNr);
      var answer = confirm("Löschung erfolgreich!");
      setLoading(false);

      if (answer) window.location.href = "/mieter";
    } catch (error) {
      console.log(error);
      alert("Es ist ein unerwarteter Fehler aufgetreten!");
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Loading isLoading={loading}>
        <div className="page-content px-0">
          <Container fluid={true}>
            <div className="wizard clearfix">
              <div className="steps clearfix">
                <ul>
                  <NavItem className={classnames({ current: activeTab === 1 })}>
                    <NavLink
                      className={classnames({
                        current: activeTab === 1,
                      })}
                      onClick={() => {
                        setactiveTab(1);
                      }}
                      disabled={!(passedSteps || []).includes(1)}
                    >
                      <span className="number">1.</span> Objekt Details
                    </NavLink>
                  </NavItem>
                  <NavItem className={classnames({ current: activeTab === 2 })}>
                    <NavLink
                      className={classnames({
                        active: activeTab === 2,
                      })}
                      onClick={() => {
                        setactiveTab(2);
                      }}
                      disabled={!(passedSteps || []).includes(2)}
                    >
                      <span className="number">2.</span> Brandmelder Infos
                    </NavLink>
                  </NavItem>
                  <NavItem className={classnames({ current: activeTab === 3 })}>
                    <NavLink
                      className={classnames({
                        active: activeTab === 3,
                      })}
                      onClick={() => {
                        setactiveTab(3);
                      }}
                      disabled={!(passedSteps || []).includes(3)}
                    >
                      <span className="number">3.</span> Bemerkungen
                    </NavLink>
                  </NavItem>
                  <NavItem className={classnames({ current: activeTab === 4 })}>
                    <NavLink
                      className={classnames({
                        active: activeTab === 4,
                      })}
                      onClick={() => {
                        setactiveTab(4);
                      }}
                      disabled={!(passedSteps || []).includes(4)}
                    >
                      <span className="number">4.</span> Unterschrift
                    </NavLink>
                  </NavItem>
                  <NavItem className={classnames({ current: activeTab === 5 })}>
                    <NavLink
                      className={classnames({
                        active: activeTab === 5,
                      })}
                      onClick={() => {
                        setactiveTab(5);
                      }}
                      disabled={!(passedSteps || []).includes(5)}
                    >
                      <span className="number">5.</span> Bestätigung
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
              <div className="content clearfix">
                <TabContent activeTab={activeTab} className="body">
                  <ObjectDetailsPane
                    report={report}
                    setReport={setReport}
                    setModal={setModal}
                    setModalLastTermin={setModalLastTermin}
                    setModalLastTerminEnfernen={setModalLastTerminEnfernen}
                    setError={setError}
                  />
                  <BrandmelderPane
                    brandmelder={brandmelder}
                    setBrandmelder={setBrandmelder}
                  />

                  <MeldungenPane report={report} setReport={setReport} />

                  <SignutarePane
                    report={report}
                    setReport={setReport}
                    monteurSignatureRef={monteurSignatureRef}
                    mieterSignatureRef={mieterSignatureRef}
                  />

                  <TabPane tabId={5}>
                    <div className="row justify-content-center">
                      <Col lg="6">
                        <div className="text-center">
                          <div className="mt-4">
                            <i className="mdi mdi-check-circle-outline text-success display-4" />
                          </div>
                          <div>
                            <h5>Speichern erfolgreich!</h5>

                            <Link
                              className="btn btn-light mt-4"
                              to={"/berichte/" + report?.lfdNr}
                            >
                              Zum Bericht
                            </Link>
                            <br />
                            <Link className="btn btn-primary mt-4" to="/Mieter">
                              Zurück zum Dashboard
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
              <div className="actions clearfix">
                <ul>
                  {activeTab != 5 && (
                    <>
                      {activeTab === 1 ? (
                        <li className="next">
                          <button
                            className="btn btn-danger"
                            to="#"
                            onClick={_ => setDeleteModal(true)}
                          >
                            Löschen
                          </button>
                        </li>
                      ) : (
                        <li className="previous">
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            Zurück
                          </Link>
                        </li>
                      )}

                      {activeTab != 4 && (
                        <li className="next">
                          <Link
                            className="btn btn-primary"
                            to="#"
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });

                              toggleTab(activeTab + 1);
                            }}
                          >
                            Weiter
                          </Link>
                        </li>
                      )}

                      {activeTab === 4 ? (
                        <li className="next">
                          <button
                            className="btn btn-success"
                            to="#"
                            onClick={handleDone}
                          >
                            Abschließen
                          </button>
                        </li>
                      ) : (
                        <>
                          <li className="next">
                            <button
                              className="btn btn-success"
                              to="#"
                              onClick={handleSubmit}
                            >
                              Speichern
                            </button>
                          </li>
                        </>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <Modal
          isOpen={deleteModal}
          centered
          toggle={() => {
            setDeleteModal();
          }}
          id="applyJobs"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setDeleteModal()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Löschung
            </ModalHeader>
            <ModalBody>
              <Row>
                <h4>Termin löschen?</h4>
                <p>Wollen Sie wirklich diesen Mieter löschen?</p>

                <Col lg={12}>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={_ => setDeleteModal(false)}
                    >
                      Abbrechen
                    </button>
                    <button
                      className="btn btn-danger ms-1"
                      onClick={handleDelete}
                    >
                      Ja, Termin löschen
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={error !== undefined}
          centered
          toggle={() => {
            setError();
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setError()}
              className="modal-header text-danger"
            >
              Fehler
            </ModalHeader>
            <ModalBody>
              <Row>
                <p>{error}</p>
                <Col lg={12}>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={_ => setError()}
                    >
                      okay
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={modalLastTermin}
          centered
          toggle={() => {
            setModalLastTermin();
          }}
          id="applyJobs"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModalLastTermin()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Neue Terminvergabe
            </ModalHeader>
            <ModalBody>
              <Row>
                <h4>Letzer Termin</h4>
                <p>
                  Mieter wird auf die Liste der 3. Termine gesetzt. Sind Sie
                  sich sicher?
                </p>

                <Col lg={12}>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={_ => setModalLastTermin(false)}
                    >
                      Abbrechen
                    </button>
                    <button
                      className="btn btn-success ms-1"
                      onClick={handleLastTermin}
                    >
                      Ja, 3. Termin festlegen
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={modalLastTerminEnfernen}
          centered
          toggle={() => {
            setModalLastTerminEnfernen();
          }}
          id="applyJobs"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModalLastTerminEnfernen()}
              id="applyJobsLabel"
              className="modal-header"
            >
              3. Termin
            </ModalHeader>
            <ModalBody>
              <Row>
                <h4>Letzer Termin entfernen</h4>
                <p>
                  Mieter wird von der Liste der 3. Termine entfernt. Sind Sie
                  sich sicher?
                </p>

                <Col lg={12}>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={_ => setModalLastTerminEnfernen(false)}
                    >
                      Abbrechen
                    </button>
                    <button
                      className="btn btn-success ms-1"
                      onClick={handleLastTerminEntfernen}
                    >
                      Ja, 3. Termin entfernen
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={modal}
          centered
          toggle={() => {
            setModal();
          }}
          id="applyJobs"
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => setModal()}
              id="applyJobsLabel"
              className="modal-header"
            >
              Neue Terminvergabe
            </ModalHeader>
            <ModalBody>
              <Row>
                <h4>Neuer Termin</h4>
                <Col lg={10} className="mb-3">
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="dd.MM.yyyy"
                    value={selectedDate}
                    lang="de"
                    onChange={e => setSelectedDate(e[0])}
                    options={{
                      dateFormat: "d.m.Y",
                      locale: {
                        ...Austria,
                        firstDayOfWeek: 1,
                      },
                    }}
                  />
                </Col>
                <Col lg={10} className="mb-3">
                  <Label>Uhrzeit:</Label>
                  <Input
                    type="text"
                    className="form-control"
                    value={selectedTime}
                    onChange={e => setSelectedTime(e.target.value)}
                  />
                </Col>

                <Col lg={12}>
                  <div className="text-end">
                    <button
                      className="btn btn-outline-secondary"
                      onClick={_ => setModal(false)}
                    >
                      Abbrechen
                    </button>
                    <button
                      className="btn btn-success ms-1"
                      onClick={handleNeuerTermin}
                    >
                      Speichern
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </div>
        </Modal>
      </Loading>
    </React.Fragment>
  );
};

export default FormWizard;
