import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={12} className="text-center">
                {new Date().getFullYear()} © MANILUMI GmbH
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
