import moment from "moment";
import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const Overview = ({ report }) => {
  return (
    <React.Fragment>
      <Col xl={3}>
        <Card>
          <CardBody>
            <h5 className="fw-semibold">Überblick</h5>

            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Laufnummer:</th>
                    <td scope="col">{report.lfdNr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Datum Tausch:</th>
                    <td scope="col">
                      {report.datumErledigt} {report.uhrzeitErledigt}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Objektnummer:</th>
                    <td scope="col">{report.objNr}</td>
                  </tr>
                  <tr>
                    <th scope="col">Strasse:</th>
                    <td scope="col">{report.adresse}</td>
                  </tr>
                  <tr>
                    <th scope="col">Ort:</th>
                    <td scope="col">
                      {(report.plz ?? "") + " " + (report.ort ?? "")}
                    </td>
                  </tr>
                  <tr>
                    <th scope="col">Mieter/in:</th>
                    <td scope="col">
                      {(report.anrede ?? "") +
                        " " +
                        (report.famName ?? "") +
                        " " +
                        (report.vorName ?? "")}
                    </td>
                  </tr>
                  {report?.genehmigungSignatur && (
                    <tr>
                      <th scope="col">Signiert am:</th>
                      <td scope="col">
                        {moment(report.lastEdited.toDate()).format(
                          "DD.MM.YYYY HH:mm"
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Overview;
